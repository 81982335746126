import { http } from '../../http';

export default {
  getDayInHistory() {
    return http.get('/dayinhistory');
  },

  getSearchResults(params = {}, filter = {}) {
    // query = encodeURIComponent(query);
    return http.get(`/search/${filter.model}/${filter.request}`, {
      params: {
        page: params.page || 1,
        per_page: params.perPage || 15,
      },
    });
  },

  sendQuestion(body) {
    return http.post('/support/send-message', body);
  },
};
