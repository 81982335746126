import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const router = useRouter();
  const config = useRuntimeConfig();

  Sentry.init({
    app: vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.env,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new ExtraErrorDataIntegration(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [[500, 599]],
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [window.location.origin, config.public.apiUrl],
      }),
    ],
    sendDefaultPii: true,
    sampleRate: config.public.env === 'production' ? 1.0 : 0,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      /**
       * Игнорировать ошибки Vite HMR
       */
      if (event.level === 'error' && event.message?.startsWith('[hmr]')) {
        return null;
      }

      /**
       * Игнорировать ошибки Vite
       */
      if (
        event.level === 'error' &&
        event.exception?.values &&
        event.exception?.values.length > 0
      ) {
        const frames = event.exception?.values[0].stacktrace?.frames || [];

        const ignoreCondition = frames.filter((f) =>
          f.filename?.endsWith('/@vite/client'),
        );

        return ignoreCondition ? null : event;
      }

      return event;
    },
    ignoreErrors: [],
    denyUrls: [],
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  // TODO: set user info
  // watch(
  //   () => useAuthStore().getUserInfo,
  //   (user) => {
  //     Sentry.setUser(user ? { id: user.id, email: user.email } : null);
  //   },
  // );

  return {
    provide: {
      sentry: Sentry,
    },
  };
});
