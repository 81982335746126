import { http } from '../../http';

export default {
  getNews(params = {}) {
    return http.get('/news', {
      params: {
        per_page: params.per_page || 14,
        page: params.page || 1,
        sort_by: params.sort_by,
      },
    });
  },

  getNewsBySlug(slug) {
    return http.get(`/news/${slug}`);
  },
};
