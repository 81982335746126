import { http } from '../../http';

export default {
  getBiographies(params = {}, filter = {}) {
    return http.get('/biographies', {
      params: {
        per_page: params.per_page || 12,
        page: params.page || 1,
        century: filter.century,
        categories: filter.categories,
        sort_by: filter.sort_by,
      },
    });
  },

  getBiographiesCategories() {
    return http.get('/biographies/categories');
  },

  getBiographyBySlug(slug) {
    return http.get(`/biographies/${slug}`);
  },
};
