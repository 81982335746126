import { default as indexVxII3jub38Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/about/index.vue?macro=true";
import { default as _91slug_93uXQ32OKC5qMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/[slug].vue?macro=true";
import { default as indexi6Ry1Kyiq2Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/index.vue?macro=true";
import { default as _91slug_93x4rgAQmwxDMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/[slug].vue?macro=true";
import { default as indexYaz9XoVTUiMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/index.vue?macro=true";
import { default as eventsUqOmfXoS98Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/events.vue?macro=true";
import { default as gpt_45chat9EG1IVnlaEMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/gpt-chat.vue?macro=true";
import { default as indexpfoZbKxjjuMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/index.vue?macro=true";
import { default as _91slug_93U08cY0hpNFMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/[slug].vue?macro=true";
import { default as indexlSLwcTYUTXMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/index.vue?macro=true";
import { default as _91slug_93zOochBIMcDMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/[slug].vue?macro=true";
import { default as indexBQyaqiECWVMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/index.vue?macro=true";
import { default as bookmarksc2kV306gsvMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/bookmarks.vue?macro=true";
import { default as indexsKcdw4TL0nMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/index.vue?macro=true";
import { default as results8eFEBd2ae4Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/results.vue?macro=true";
import { default as indexCD73EMNLhMMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/projects/index.vue?macro=true";
import { default as _91slug_93sXGU2zqqKgMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/[slug].vue?macro=true";
import { default as indexjIA5mfY8PuMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/index.vue?macro=true";
import { default as _91slug_939JCRI3GebRMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/[slug].vue?macro=true";
import { default as indexTnxHbRJsK8Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/index.vue?macro=true";
import { default as _91document_93B1QJ3D004FMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/[document].vue?macro=true";
import { default as indexVTQYHF8BJuMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/index.vue?macro=true";
import { default as index23RRDGq3G5Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/index.vue?macro=true";
import { default as _91slug_93xiwLGoIymgMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/[slug].vue?macro=true";
import { default as indexeTfUVYEl06Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/index.vue?macro=true";
import { default as _91slug_93QGuJIKJFWCMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/[slug].vue?macro=true";
import { default as indexdQ8WJwCnSIMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/index.vue?macro=true";
import { default as _91slug_93IYk7tybjEJMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/[slug].vue?macro=true";
import { default as indexMK87oyGevjMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/index.vue?macro=true";
import { default as search09rmcPtUaoMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/search.vue?macro=true";
import { default as summerL21qbgVwTRMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/summer.vue?macro=true";
import { default as _91slug_93nsjB0WPCV4Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/tags/[slug].vue?macro=true";
import { default as _91chapter_93v1zL0mBbraMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue?macro=true";
import { default as _91slug_93rrVRzSKog7Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue?macro=true";
import { default as _91slug_93HSNamcTFJxMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue?macro=true";
import { default as _91slug_93rqIKNiD3CWMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue?macro=true";
import { default as indexE9YLdt8x6VMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue?macro=true";
import { default as _91slug_934lncBukHOGMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue?macro=true";
import { default as _91slug_93xy3kwQS7ghMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue?macro=true";
import { default as _91category_93qEKjRKkI05Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category].vue?macro=true";
import { default as indextq5ECePyJkMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/index.vue?macro=true";
import { default as _91parent_93mXzWfAOJAMMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent].vue?macro=true";
import { default as _91slug_933A22ZprVVLMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/glossary/[slug].vue?macro=true";
import { default as indexkbuX2ieCMcMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/index.vue?macro=true";
import { default as teacherqJ8Cq87EwTMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher.vue?macro=true";
import { default as _91slug_93GSucaFb6H1Meta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/[slug].vue?macro=true";
import { default as indexZ5TEAxpFxOMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/index.vue?macro=true";
import { default as indexkbBaMlq22iMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/timeline/index.vue?macro=true";
import { default as victory_45day20dInAkorRMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/victory-day.vue?macro=true";
import { default as _91slug_9355oF2vJX0uMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/[slug].vue?macro=true";
import { default as indexSUFlv6LWtkMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/index.vue?macro=true";
import { default as _91slug_93He910FDvFmMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/[slug].vue?macro=true";
import { default as indexbaYp0GhQoYMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/index.vue?macro=true";
import { default as _91slug_93iAEUFo8vdcMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/[slug].vue?macro=true";
import { default as indexUnOCXbEeKDMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/index.vue?macro=true";
import { default as indexlp51MtIlPqMeta } from "/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/memdates/index.vue?macro=true";
export default [
  {
    name: indexVxII3jub38Meta?.name ?? "about",
    path: indexVxII3jub38Meta?.path ?? "/about",
    meta: indexVxII3jub38Meta || {},
    alias: indexVxII3jub38Meta?.alias || [],
    redirect: indexVxII3jub38Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uXQ32OKC5qMeta?.name ?? "authors-slug",
    path: _91slug_93uXQ32OKC5qMeta?.path ?? "/authors/:slug()",
    meta: _91slug_93uXQ32OKC5qMeta || {},
    alias: _91slug_93uXQ32OKC5qMeta?.alias || [],
    redirect: _91slug_93uXQ32OKC5qMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexi6Ry1Kyiq2Meta?.name ?? "authors",
    path: indexi6Ry1Kyiq2Meta?.path ?? "/authors",
    meta: indexi6Ry1Kyiq2Meta || {},
    alias: indexi6Ry1Kyiq2Meta?.alias || [],
    redirect: indexi6Ry1Kyiq2Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/authors/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93x4rgAQmwxDMeta?.name ?? "collections-slug",
    path: _91slug_93x4rgAQmwxDMeta?.path ?? "/collections/:slug()",
    meta: _91slug_93x4rgAQmwxDMeta || {},
    alias: _91slug_93x4rgAQmwxDMeta?.alias || [],
    redirect: _91slug_93x4rgAQmwxDMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYaz9XoVTUiMeta?.name ?? "collections",
    path: indexYaz9XoVTUiMeta?.path ?? "/collections",
    meta: indexYaz9XoVTUiMeta || {},
    alias: indexYaz9XoVTUiMeta?.alias || [],
    redirect: indexYaz9XoVTUiMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: eventsUqOmfXoS98Meta?.name ?? "events",
    path: eventsUqOmfXoS98Meta?.path ?? "/events",
    meta: eventsUqOmfXoS98Meta || {},
    alias: eventsUqOmfXoS98Meta?.alias || [],
    redirect: eventsUqOmfXoS98Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/events.vue").then(m => m.default || m)
  },
  {
    name: gpt_45chat9EG1IVnlaEMeta?.name ?? "gpt-chat",
    path: gpt_45chat9EG1IVnlaEMeta?.path ?? "/gpt-chat",
    meta: gpt_45chat9EG1IVnlaEMeta || {},
    alias: gpt_45chat9EG1IVnlaEMeta?.alias || [],
    redirect: gpt_45chat9EG1IVnlaEMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/gpt-chat.vue").then(m => m.default || m)
  },
  {
    name: indexpfoZbKxjjuMeta?.name ?? "index",
    path: indexpfoZbKxjjuMeta?.path ?? "/",
    meta: indexpfoZbKxjjuMeta || {},
    alias: indexpfoZbKxjjuMeta?.alias || [],
    redirect: indexpfoZbKxjjuMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U08cY0hpNFMeta?.name ?? "listen-courses-slug",
    path: _91slug_93U08cY0hpNFMeta?.path ?? "/listen/courses/:slug()",
    meta: _91slug_93U08cY0hpNFMeta || {},
    alias: _91slug_93U08cY0hpNFMeta?.alias || [],
    redirect: _91slug_93U08cY0hpNFMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlSLwcTYUTXMeta?.name ?? "listen-courses",
    path: indexlSLwcTYUTXMeta?.path ?? "/listen/courses",
    meta: indexlSLwcTYUTXMeta || {},
    alias: indexlSLwcTYUTXMeta?.alias || [],
    redirect: indexlSLwcTYUTXMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/courses/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zOochBIMcDMeta?.name ?? "listen-lectures-slug",
    path: _91slug_93zOochBIMcDMeta?.path ?? "/listen/lectures/:slug()",
    meta: _91slug_93zOochBIMcDMeta || {},
    alias: _91slug_93zOochBIMcDMeta?.alias || [],
    redirect: _91slug_93zOochBIMcDMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBQyaqiECWVMeta?.name ?? "listen-lectures",
    path: indexBQyaqiECWVMeta?.path ?? "/listen/lectures",
    meta: indexBQyaqiECWVMeta || {},
    alias: indexBQyaqiECWVMeta?.alias || [],
    redirect: indexBQyaqiECWVMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/listen/lectures/index.vue").then(m => m.default || m)
  },
  {
    name: bookmarksc2kV306gsvMeta?.name ?? "profile-bookmarks",
    path: bookmarksc2kV306gsvMeta?.path ?? "/profile/bookmarks",
    meta: bookmarksc2kV306gsvMeta || {},
    alias: bookmarksc2kV306gsvMeta?.alias || [],
    redirect: bookmarksc2kV306gsvMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/bookmarks.vue").then(m => m.default || m)
  },
  {
    name: indexsKcdw4TL0nMeta?.name ?? "profile",
    path: indexsKcdw4TL0nMeta?.path ?? "/profile",
    meta: indexsKcdw4TL0nMeta || {},
    alias: indexsKcdw4TL0nMeta?.alias || [],
    redirect: indexsKcdw4TL0nMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: results8eFEBd2ae4Meta?.name ?? "profile-results",
    path: results8eFEBd2ae4Meta?.path ?? "/profile/results",
    meta: results8eFEBd2ae4Meta || {},
    alias: results8eFEBd2ae4Meta?.alias || [],
    redirect: results8eFEBd2ae4Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/profile/results.vue").then(m => m.default || m)
  },
  {
    name: indexCD73EMNLhMMeta?.name ?? "projects",
    path: indexCD73EMNLhMMeta?.path ?? "/projects",
    meta: indexCD73EMNLhMMeta || {},
    alias: indexCD73EMNLhMMeta?.alias || [],
    redirect: indexCD73EMNLhMMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sXGU2zqqKgMeta?.name ?? "read-articles-slug",
    path: _91slug_93sXGU2zqqKgMeta?.path ?? "/read/articles/:slug()",
    meta: _91slug_93sXGU2zqqKgMeta || {},
    alias: _91slug_93sXGU2zqqKgMeta?.alias || [],
    redirect: _91slug_93sXGU2zqqKgMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjIA5mfY8PuMeta?.name ?? "read-articles",
    path: indexjIA5mfY8PuMeta?.path ?? "/read/articles",
    meta: indexjIA5mfY8PuMeta || {},
    alias: indexjIA5mfY8PuMeta?.alias || [],
    redirect: indexjIA5mfY8PuMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939JCRI3GebRMeta?.name ?? "read-biographies-slug",
    path: _91slug_939JCRI3GebRMeta?.path ?? "/read/biographies/:slug()",
    meta: _91slug_939JCRI3GebRMeta || {},
    alias: _91slug_939JCRI3GebRMeta?.alias || [],
    redirect: _91slug_939JCRI3GebRMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTnxHbRJsK8Meta?.name ?? "read-biographies",
    path: indexTnxHbRJsK8Meta?.path ?? "/read/biographies",
    meta: indexTnxHbRJsK8Meta || {},
    alias: indexTnxHbRJsK8Meta?.alias || [],
    redirect: indexTnxHbRJsK8Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/biographies/index.vue").then(m => m.default || m)
  },
  {
    name: _91document_93B1QJ3D004FMeta?.name ?? "read-documents-slug-document",
    path: _91document_93B1QJ3D004FMeta?.path ?? "/read/documents/:slug()/:document()",
    meta: _91document_93B1QJ3D004FMeta || {},
    alias: _91document_93B1QJ3D004FMeta?.alias || [],
    redirect: _91document_93B1QJ3D004FMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/[document].vue").then(m => m.default || m)
  },
  {
    name: indexVTQYHF8BJuMeta?.name ?? "read-documents-slug",
    path: indexVTQYHF8BJuMeta?.path ?? "/read/documents/:slug()",
    meta: indexVTQYHF8BJuMeta || {},
    alias: indexVTQYHF8BJuMeta?.alias || [],
    redirect: indexVTQYHF8BJuMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index23RRDGq3G5Meta?.name ?? "read-documents",
    path: index23RRDGq3G5Meta?.path ?? "/read/documents",
    meta: index23RRDGq3G5Meta || {},
    alias: index23RRDGq3G5Meta?.alias || [],
    redirect: index23RRDGq3G5Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/documents/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xiwLGoIymgMeta?.name ?? "read-monuments-slug",
    path: _91slug_93xiwLGoIymgMeta?.path ?? "/read/monuments/:slug()",
    meta: _91slug_93xiwLGoIymgMeta || {},
    alias: _91slug_93xiwLGoIymgMeta?.alias || [],
    redirect: _91slug_93xiwLGoIymgMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexeTfUVYEl06Meta?.name ?? "read-monuments",
    path: indexeTfUVYEl06Meta?.path ?? "/read/monuments",
    meta: indexeTfUVYEl06Meta || {},
    alias: indexeTfUVYEl06Meta?.alias || [],
    redirect: indexeTfUVYEl06Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/monuments/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QGuJIKJFWCMeta?.name ?? "read-news-slug",
    path: _91slug_93QGuJIKJFWCMeta?.path ?? "/read/news/:slug()",
    meta: _91slug_93QGuJIKJFWCMeta || {},
    alias: _91slug_93QGuJIKJFWCMeta?.alias || [],
    redirect: _91slug_93QGuJIKJFWCMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexdQ8WJwCnSIMeta?.name ?? "read-news",
    path: indexdQ8WJwCnSIMeta?.path ?? "/read/news",
    meta: indexdQ8WJwCnSIMeta || {},
    alias: indexdQ8WJwCnSIMeta?.alias || [],
    redirect: indexdQ8WJwCnSIMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IYk7tybjEJMeta?.name ?? "read-science-slug",
    path: _91slug_93IYk7tybjEJMeta?.path ?? "/read/science/:slug()",
    meta: _91slug_93IYk7tybjEJMeta || {},
    alias: _91slug_93IYk7tybjEJMeta?.alias || [],
    redirect: _91slug_93IYk7tybjEJMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMK87oyGevjMeta?.name ?? "read-science",
    path: indexMK87oyGevjMeta?.path ?? "/read/science",
    meta: indexMK87oyGevjMeta || {},
    alias: indexMK87oyGevjMeta?.alias || [],
    redirect: indexMK87oyGevjMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/read/science/index.vue").then(m => m.default || m)
  },
  {
    name: search09rmcPtUaoMeta?.name ?? "search",
    path: search09rmcPtUaoMeta?.path ?? "/search",
    meta: search09rmcPtUaoMeta || {},
    alias: search09rmcPtUaoMeta?.alias || [],
    redirect: search09rmcPtUaoMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: summerL21qbgVwTRMeta?.name ?? "summer",
    path: summerL21qbgVwTRMeta?.path ?? "/summer",
    meta: summerL21qbgVwTRMeta || {},
    alias: summerL21qbgVwTRMeta?.alias || [],
    redirect: summerL21qbgVwTRMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/summer.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nsjB0WPCV4Meta?.name ?? "tags-slug",
    path: _91slug_93nsjB0WPCV4Meta?.path ?? "/tags/:slug()",
    meta: _91slug_93nsjB0WPCV4Meta || {},
    alias: _91slug_93nsjB0WPCV4Meta?.alias || [],
    redirect: _91slug_93nsjB0WPCV4Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/tags/[slug].vue").then(m => m.default || m)
  },
  {
    path: teacherqJ8Cq87EwTMeta?.path ?? "/teacher",
    children: [
  {
    path: _91parent_93mXzWfAOJAMMeta?.path ?? ":parent()",
    children: [
  {
    path: _91category_93qEKjRKkI05Meta?.path ?? ":category()",
    children: [
  {
    name: _91chapter_93v1zL0mBbraMeta?.name ?? "teacher-parent-category-chapter",
    path: _91chapter_93v1zL0mBbraMeta?.path ?? ":chapter()",
    meta: _91chapter_93v1zL0mBbraMeta || {},
    alias: _91chapter_93v1zL0mBbraMeta?.alias || [],
    redirect: _91chapter_93v1zL0mBbraMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/[chapter].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rrVRzSKog7Meta?.name ?? "teacher-parent-category-article-slug",
    path: _91slug_93rrVRzSKog7Meta?.path ?? "article/:slug()",
    meta: _91slug_93rrVRzSKog7Meta || {},
    alias: _91slug_93rrVRzSKog7Meta?.alias || [],
    redirect: _91slug_93rrVRzSKog7Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HSNamcTFJxMeta?.name ?? "teacher-parent-category-audiolecture-slug",
    path: _91slug_93HSNamcTFJxMeta?.path ?? "audiolecture/:slug()",
    meta: _91slug_93HSNamcTFJxMeta || {},
    alias: _91slug_93HSNamcTFJxMeta?.alias || [],
    redirect: _91slug_93HSNamcTFJxMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/audiolecture/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rqIKNiD3CWMeta?.name ?? "teacher-parent-category-chapter-slug",
    path: _91slug_93rqIKNiD3CWMeta?.path ?? "chapter/:slug()",
    meta: _91slug_93rqIKNiD3CWMeta || {},
    alias: _91slug_93rqIKNiD3CWMeta?.alias || [],
    redirect: _91slug_93rqIKNiD3CWMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/chapter/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexE9YLdt8x6VMeta?.name ?? "teacher-parent-category",
    path: indexE9YLdt8x6VMeta?.path ?? "",
    meta: indexE9YLdt8x6VMeta || {},
    alias: indexE9YLdt8x6VMeta?.alias || [],
    redirect: indexE9YLdt8x6VMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lncBukHOGMeta?.name ?? "teacher-parent-category-test-slug",
    path: _91slug_934lncBukHOGMeta?.path ?? "test/:slug()",
    meta: _91slug_934lncBukHOGMeta || {},
    alias: _91slug_934lncBukHOGMeta?.alias || [],
    redirect: _91slug_934lncBukHOGMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/test/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xy3kwQS7ghMeta?.name ?? "teacher-parent-category-videolecture-slug",
    path: _91slug_93xy3kwQS7ghMeta?.path ?? "videolecture/:slug()",
    meta: _91slug_93xy3kwQS7ghMeta || {},
    alias: _91slug_93xy3kwQS7ghMeta?.alias || [],
    redirect: _91slug_93xy3kwQS7ghMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category]/videolecture/[slug].vue").then(m => m.default || m)
  }
],
    name: _91category_93qEKjRKkI05Meta?.name ?? undefined,
    meta: _91category_93qEKjRKkI05Meta || {},
    alias: _91category_93qEKjRKkI05Meta?.alias || [],
    redirect: _91category_93qEKjRKkI05Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/[category].vue").then(m => m.default || m)
  },
  {
    name: indextq5ECePyJkMeta?.name ?? "teacher-parent",
    path: indextq5ECePyJkMeta?.path ?? "",
    meta: indextq5ECePyJkMeta || {},
    alias: indextq5ECePyJkMeta?.alias || [],
    redirect: indextq5ECePyJkMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent]/index.vue").then(m => m.default || m)
  }
],
    name: _91parent_93mXzWfAOJAMMeta?.name ?? undefined,
    meta: _91parent_93mXzWfAOJAMMeta || {},
    alias: _91parent_93mXzWfAOJAMMeta?.alias || [],
    redirect: _91parent_93mXzWfAOJAMMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/[parent].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933A22ZprVVLMeta?.name ?? "teacher-glossary-slug",
    path: _91slug_933A22ZprVVLMeta?.path ?? "glossary/:slug()",
    meta: _91slug_933A22ZprVVLMeta || {},
    alias: _91slug_933A22ZprVVLMeta?.alias || [],
    redirect: _91slug_933A22ZprVVLMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/glossary/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkbuX2ieCMcMeta?.name ?? "teacher",
    path: indexkbuX2ieCMcMeta?.path ?? "",
    meta: indexkbuX2ieCMcMeta || {},
    alias: indexkbuX2ieCMcMeta?.alias || [],
    redirect: indexkbuX2ieCMcMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher/index.vue").then(m => m.default || m)
  }
],
    name: teacherqJ8Cq87EwTMeta?.name ?? undefined,
    meta: teacherqJ8Cq87EwTMeta || {},
    alias: teacherqJ8Cq87EwTMeta?.alias || [],
    redirect: teacherqJ8Cq87EwTMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/teacher.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GSucaFb6H1Meta?.name ?? "tests-slug",
    path: _91slug_93GSucaFb6H1Meta?.path ?? "/tests/:slug()",
    meta: _91slug_93GSucaFb6H1Meta || {},
    alias: _91slug_93GSucaFb6H1Meta?.alias || [],
    redirect: _91slug_93GSucaFb6H1Meta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZ5TEAxpFxOMeta?.name ?? "tests",
    path: indexZ5TEAxpFxOMeta?.path ?? "/tests",
    meta: indexZ5TEAxpFxOMeta || {},
    alias: indexZ5TEAxpFxOMeta?.alias || [],
    redirect: indexZ5TEAxpFxOMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexkbBaMlq22iMeta?.name ?? "timeline",
    path: indexkbBaMlq22iMeta?.path ?? "/timeline",
    meta: indexkbBaMlq22iMeta || {},
    alias: indexkbBaMlq22iMeta?.alias || [],
    redirect: indexkbBaMlq22iMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: victory_45day20dInAkorRMeta?.name ?? "victory-day",
    path: victory_45day20dInAkorRMeta?.path ?? "/victory-day",
    meta: victory_45day20dInAkorRMeta || {},
    alias: victory_45day20dInAkorRMeta?.alias || [],
    redirect: victory_45day20dInAkorRMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/victory-day.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9355oF2vJX0uMeta?.name ?? "watch-courses-slug",
    path: _91slug_9355oF2vJX0uMeta?.path ?? "/watch/courses/:slug()",
    meta: _91slug_9355oF2vJX0uMeta || {},
    alias: _91slug_9355oF2vJX0uMeta?.alias || [],
    redirect: _91slug_9355oF2vJX0uMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSUFlv6LWtkMeta?.name ?? "watch-courses",
    path: indexSUFlv6LWtkMeta?.path ?? "/watch/courses",
    meta: indexSUFlv6LWtkMeta || {},
    alias: indexSUFlv6LWtkMeta?.alias || [],
    redirect: indexSUFlv6LWtkMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/courses/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93He910FDvFmMeta?.name ?? "watch-films-slug",
    path: _91slug_93He910FDvFmMeta?.path ?? "/watch/films/:slug()",
    meta: _91slug_93He910FDvFmMeta || {},
    alias: _91slug_93He910FDvFmMeta?.alias || [],
    redirect: _91slug_93He910FDvFmMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbaYp0GhQoYMeta?.name ?? "watch-films",
    path: indexbaYp0GhQoYMeta?.path ?? "/watch/films",
    meta: indexbaYp0GhQoYMeta || {},
    alias: indexbaYp0GhQoYMeta?.alias || [],
    redirect: indexbaYp0GhQoYMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/films/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iAEUFo8vdcMeta?.name ?? "watch-lectures-slug",
    path: _91slug_93iAEUFo8vdcMeta?.path ?? "/watch/lectures/:slug()",
    meta: _91slug_93iAEUFo8vdcMeta || {},
    alias: _91slug_93iAEUFo8vdcMeta?.alias || [],
    redirect: _91slug_93iAEUFo8vdcMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexUnOCXbEeKDMeta?.name ?? "watch-lectures",
    path: indexUnOCXbEeKDMeta?.path ?? "/watch/lectures",
    meta: indexUnOCXbEeKDMeta || {},
    alias: indexUnOCXbEeKDMeta?.alias || [],
    redirect: indexUnOCXbEeKDMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/lectures/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp51MtIlPqMeta?.name ?? "watch-memdates",
    path: indexlp51MtIlPqMeta?.path ?? "/watch/memdates",
    meta: indexlp51MtIlPqMeta || {},
    alias: indexlp51MtIlPqMeta?.alias || [],
    redirect: indexlp51MtIlPqMeta?.redirect || undefined,
    component: () => import("/var/www/delo1.jet-mix.ru/histrf-client/pages/watch/memdates/index.vue").then(m => m.default || m)
  }
]