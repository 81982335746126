import { http } from '../../http';

export default {
  getArticles(params = {}, filter = {}) {
    return http.get('/articles', {
      params: {
        per_page: params.per_page || 16,
        page: params.page || 1,
        sort_by: filter?.sort_by,
        category: filter?.category,
        start_century: filter?.startCentury,
        end_century: filter?.endCentury,
        excludeId: params.excludeId,
        is_main_page: params.isMainPage,
      },
    });
  },

  getMonuments(params = {}, filter = {}) {
    return http.get('/monuments', {
      params: {
        per_page: params.per_page || 16,
        page: params.page || 1,
        sort_by: filter.sort_by,
        category: filter.category,
        start_century: filter.startCentury,
        end_century: filter.endCentury,
        excludeId: params.excludeId,
        is_main_page: params.isMainPage,
      },
    });
  },

  getScience(params = {}, filter = {}) {
    return http.get('/science', {
      params: {
        per_page: params.per_page || 16,
        page: params.page || 1,
        sort_by: filter.sort_by,
        category: filter.category,
        start_century: filter.startCentury,
        end_century: filter.endCentury,
        excludeId: params.excludeId,
        is_main_page: params.isMainPage,
      },
    });
  },

  getCustomArticles(params = {}) {
    return http.get('/articles', {
      params: {
        only_custom_articles: true,
        per_page: params.per_page || 16,
        page: params.page || 1,
      },
    });
  },

  getArticlesCategories(filter = {}) {
    return http.get('/categories/article', {
      params: {
        start_century: filter?.startCentury,
        end_century: filter?.endCentury,
      },
    });
  },

  getArticleBySlug(slug) {
    return http.get(`/articles/${slug}`);
  },

  getTopArticleTags(amount = 32, sort = 'count') {
    return http.get(`/tags?per_page=${amount}&sort_by=${sort}`);
  },

  getArticlesByTag(params = {}, filters = {}) {
    return http.get(`/articles/tags/${params.slug}`, {
      params: {
        per_page: params.per_page || 16,
        page: params.page || 1,
        sort_by: filters.sort_by,
      },
    });
  },

  getRandomArticle(count = 9, params = {}) {
    return http.get('/random/articles', {
      params: {
        categoryId: params.categoryId,
        excludeId: params.excludeId,
        rand: count,
      },
    });
  },

  getPopularArticles(days, count) {
    return http.get('/popular/articles', { params: { days, qty: count } });
  },

  getSummerArticles() {
    return http.get('/popular/summer');
  },
};
